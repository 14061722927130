<template>
    <div>
      <option-comp :type="type"></option-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import OptionComp from "@/components/admin/option/OptionComp";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
    name: "edit-option",
    components : {OptionComp},

    setup() {

        const type = ref('edit');
        onMounted(() => {
            setCurrentPageBreadcrumbs("Update Option", ["Options"] , checkPermission('options.store') ? 'create-option': null);
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
